table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 10px;
}

table tr {
  border-bottom: solid 1px #000;
}

table th {
  padding: 10px;
  border: solid 1px #000;
}

table td {
  padding: 10px;
  text-align: center;
  border: solid 1px #000;
}